import React from 'react'
import './SubCategories.css'
import pdfdownload from '../../../../../assets/pdf/Steam Flow Meter.pdf'
import img1 from  '../../../../../assets/products/SFM3.png'
import 'react-toastify/dist/ReactToastify.css'

export default function SteamFlowMeter() {
    return (

<div className="container-brochure-main">
        <div className='brochure'>
            <img src={img1} alt="Uploading" />
        </div>
        <a rel='noreferrer' className='pdflink' target={"_blank"} href={pdfdownload} download="Steam_Flow_Meter" >Download</a>
        </div>
    )
}