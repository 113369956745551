import React from 'react'
import './Categories.css'

import { useNavigate } from "react-router-dom";

import product5 from  '../../../../assets/products/new product 5.png';
import product15 from '../../../../assets/products/new product 15.png';

import { fuel, genset } from './ProductDetail/ProductsArray';
   
const productMap = [
    {
        productimg: product5,
        heading: "FUEL LEVEL SENSOR",
        para: "The FLS3600 offers precise consumption & availability of fuel in fuel tank with advanced LIDAR technology as compare with other well-known brands. ",
        name: "FUEL LEVEL SENSOR",
        arr: fuel,
        path: "/products/generator-set-monitoring/fuel-level-sensor" // Added path
    },
    {
        productimg: product15,
        heading: "GENSET HEALTH MONITORING SYSTEM",
        para: "The CELL900G is a cutting-edge solution for monitoring & maintaining generator sets, providing real-time insights into their operational health & performance across various industrial & commercial applications.",
        name: "GENSET HEALTH MONITORING SYSTEM",
        arr: genset,
        path: "/products/generator-set-monitoring/genset-health-monitoring-system" // Added path
    },
]

export default function Product() {
  const navigate = useNavigate();
  return (
      <>
          <div className="container-latestproduct-main">
              <div className='productbar'></div>
              <div className="heading-latestproduct-top">GENERATOR SET MONITORING</div>
              <div className="container-latestproduct">
                  {
                      productMap.map((product, i, index) => {
                          return (
                              <div key={i} className="latestproduct">
                                  <div className="img-wrapper" onClick={() => {
                                      navigate(product.path, {
                                          state: {
                                              name: product.name,
                                              arrrr: product.arr,
                                          },
                                      });
                                  }}>
                                      <img className="inner-img" src={product.productimg} alt={product.heading} />
                                  </div>
                                  <div key={index}
                                       className={product.name === "FUEL LEVEL SENSOR" ? 'fls-padding' : ''} >
                                  <div className="heading-latestproduct">{product.heading}</div></div>
                                  <div className="para-homeproduct">
                                      {product.para}
                                  </div>
                                  <div onClick={() => {
                                      navigate(product.path, {
                                          state: {
                                              name: product.name,
                                              arrrr: product.arr,
                                          },
                                      });
                                  }}>
                                      <div id="container">
                                        <button className="learn-more">
        <span className="circle" aria-hidden="true">
          <span className="icon arrow"></span>
        </span>
        <span className="button-text">Read More</span>
      </button></div>
                                  </div>
                              </div>
                          )
                      })
                  }
              </div>
          </div>
      </>
  )
}
