import React from 'react'
import Product from '../Home/Product/ProductProduct/Flow Metering'

export default function FlowMetering() {
  return (
    <>
        <Product/>
    </>
  )
}
