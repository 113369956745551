import './App.css';

import React, { useState } from 'react';

// import HomeMain from './MainComponents/HomeMain.jsx';
// import ContactUsMain from './MainComponents/ContactUsMain.jsx';
// import BmsMain from './MainComponents/BmsMain.jsx';
// import AboutMain from './MainComponents/AboutMain.jsx';
// import ProductMain from './MainComponents/ProductMain.jsx';
// import ProjectMain from './MainComponents/ProjectMain.jsx';
// import ProjectCompanyMain from './MainComponents/Project1/ProjectCompanyMain.jsx';
// import CompanyProjectMain from './MainComponents/Project1/Project2/CompanyProjectMain.jsx';
// import ProductCompanyMain from './MainComponents/Product1/ProductCompanyMain.jsx';
// import CompanyProductMain from './MainComponents/Product1/Product2/CompanyProductMain.jsx';
// import CareerMain from './MainComponents/CareerMain.jsx';
// import PageNot from './MainComponents/PageNot.jsx';


import Navbar from './Component/Navbar/Navbar';
import HomeMain from './Component/Home/HomeMain';
import AboutMain from './Component/About/AboutMain';
import Bms from './Component/Bms/Bms'
import Contact from './Component/Contact/Contact'
import Career from './Component/Career/Career'
// import Main from './Component/ComponentMain'
import Error from './Component/PageNotFound/PageErrorMain'
import ProjectDetail from './Component/Project/ProjectProduct/ProjectDetail/_ProjectDetailMain'
import Footer from './Component/Footer/Footer';





import BMSCombustionControl from './Component/Product/ProComponent';
import FlowMetering from './Component/Product/ProComponent1';
import Sensors from './Component/Product/ProComponent2';
import GeneratorSetMonitoring from './Component/Product/ProComponent3';
import DataLoggers from './Component/Product/ProComponent4';
import CondensateRecovery from './Component/Product/ProComponent5';





import FusionSystem from './Component/Home/Product/ProductProduct/ProductDetail/Fusion System';
import OxygenAnalyzer from './Component/Home/Product/ProductProduct/ProductDetail/Oxygen Analyzer';
import EmissionMonitoringSystem from './Component/Home/Product/ProductProduct/ProductDetail/Emission Monitoring System';

import SteamFlowMeter from './Component/Home/Product/ProductProduct/ProductDetail/Steam Flow Meter';
import ThermalMassFlowMeter from './Component/Home/Product/ProductProduct/ProductDetail/Thermal Mass Flow Meter';
import ElectromagneticWaterFlowMeter from './Component/Home/Product/ProductProduct/ProductDetail/Electromagnetic Water Flow Meter';
import EnergyMeter from './Component/Home/Product/ProductProduct/ProductDetail/Energy Meter';

import WaterQualityAnalyzer from './Component/Home/Product/ProductProduct/ProductDetail/Water Quality Analyzer';
import EnvironmentalSensor from './Component/Home/Product/ProductProduct/ProductDetail/Environmental Sensor';
import ResistorDependingTemperatureSensor from './Component/Home/Product/ProductProduct/ProductDetail/Resistor Depending Temperature Sensor';
import TemperatureHumidityDataTerminal from './Component/Home/Product/ProductProduct/ProductDetail/Temperature Humidity Data Terminal';
import IRTemperatureSensorDataTerminal from './Component/Home/Product/ProductProduct/ProductDetail/IR Temperature Sensor Data Terminal';

import FuelLevelSensor from './Component/Home/Product/ProductProduct/ProductDetail/Fuel Level Sensor';
import GenSetHealthMonitoringSystem from './Component/Home/Product/ProductProduct/ProductDetail/GenSet Health Monitoring System';

import GSMDataLogger from './Component/Home/Product/ProductProduct/ProductDetail/GSM Data Logger';
import MilkTankControlMonitoringSystem from './Component/Home/Product/ProductProduct/ProductDetail/Milk Tank Control Monitoring System';

import CondensateMeteringPump from './Component/Home/Product/ProductProduct/ProductDetail/Condensate Metering Pump';



import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import ProjectMain from './Component/Project/ProjectMain';

function App() {
  const [setLoading] = useState(true);
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 2000);
  }
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/home" element={<HomeMain />} />
          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route path="/about" element={<AboutMain />} />
          <Route path="/project" element={<ProjectMain />} />
          <Route path="/bms" element={<Bms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/Projects" element={<ProjectDetail />} />
          





          <Route path="/products/combustion-control" element={<BMSCombustionControl  />} />
          <Route path="/products/flow-metering" element={<FlowMetering  />} />
          <Route path="/products/sensors" element={<Sensors  />} />
          <Route path="/products/generator-set-monitoring" element={<GeneratorSetMonitoring  />} />
          <Route path="/products/data-loggers" element={<DataLoggers />} />
          <Route path="/products/condensate-recovery" element={<CondensateRecovery />} />






          <Route path="/products/combustion-control/fusion-system" element={<FusionSystem />} />
          <Route path="/products/combustion-control/oxygen-analyzer" element={<OxygenAnalyzer />} />
          <Route path="/products/combustion-control/emission-monitoring-system" element={<EmissionMonitoringSystem />} />

          <Route path="/products/flow-metering/steam-flow-meter" element={<SteamFlowMeter />} />
          <Route path="/products/flow-metering/thermal-mass-flow-meter" element={<ThermalMassFlowMeter />} />
          <Route path="/products/flow-metering/electromagnetic-water-flow-meter" element={<ElectromagneticWaterFlowMeter />} />
          <Route path="/products/flow-metering/energy-meter" element={<EnergyMeter />} />

          <Route path="/products/sensors/water-quality-analyzer" element={<WaterQualityAnalyzer />} />
          <Route path="/products/sensors/environmental-sensor" element={<EnvironmentalSensor />} />
          <Route path="/products/sensors/resistor-depending-temperature-sensor" element={<ResistorDependingTemperatureSensor />} />
          <Route path="/products/sensors/temperature-&-humidity-data-terminal" element={<TemperatureHumidityDataTerminal />} />
          <Route path="/products/sensors/ir-temperature-sensor-data-terminal" element={<IRTemperatureSensorDataTerminal />} />

          <Route path="/products/generator-set-monitoring/fuel-level-sensor" element={<FuelLevelSensor />} />
          <Route path="/products/generator-set-monitoring/genset-health-monitoring-system" element={<GenSetHealthMonitoringSystem />} />

          <Route path="/products/data-loggers/gsm-data-logger" element={<GSMDataLogger />} />
          <Route path="/products/data-loggers/milk-tank-control-&-monitoring-system" element={<MilkTankControlMonitoringSystem />} />

          <Route path="/products/condensate-recovery/condensate-metering-pump" element={<CondensateMeteringPump />} />

          {/* <Route exact path="/about" element={<AboutMain />}/> */}
          {/* <Route exact path="/product" element={<ProductMain />}/> */}
          {/* <Route exact path="/project" element={<ProjectMain />}/> */}
          {/* <Route exact path="/project/projectCompany" element={<ProjectCompanyMain />}/> */}
          {/* <Route exact path="/project/projectCompany/Projects" element={<CompanyProjectMain />}/> */}
          {/* <Route exact path="/product/productCompany" element={<ProductCompanyMain />}/> */}
          {/* <Route exact path="/product/productCompany/Products" element={<CompanyProductMain />}/> */}
          {/* <Route exact path="/bms" element={<BmsMain />}/> */}
          {/* <Route exact path="/contactus" element={<ContactUsMain />}/> */}
          {/* <Route exact path="/career" element={<CareerMain />}/> */}

          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
