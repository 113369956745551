import React, { useRef } from 'react'

import './Career.css'
import img1 from '../../assets/background/Career1.jpg'

import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


export default function Career() {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_6uhbm0r', 'template_jz8covr', e.target, 'QxoHaRkDQ3EeE0bVy')
            .then((result) => {
                console.log("Email Sended SuccessFully", result.text);
                toast.success("Email Sended SuccessFully")

            }, (error) => {
                console.log("Email Not Sended", error.text);
                toast.error("Email Not Sended")

            });
        e.target.reset()
    };
    return (
        <>
        <div className="career-container">
      <div className="career-text">
        <h1>Why Choose Us?</h1>
        <br></br>
        <h3>Passion for the Mission:</h3>
        <p className="para">
        We are passionate about our mission to provide care to those in need in our community, which keeps us motivated and excited about doing our best work.
        </p>
        <br></br>
        <h3>Opportunity to Grow:</h3>
        <p className="para">
        Join us and grow your career with a company that values personal and professional development.
        </p>
        <br></br>
        <h3>Innovative Environment:</h3>
        <p className="para">
        We foster an innovative environment where you can contribute your ideas, implement them, and truly make a difference.
        </p>
        <br></br>
        <h3>Teamwork and Collaboration:</h3>
        <p className="para">
        Our team collaborates to achieve common goals. Your ideas will be valued and your skills put to use for the team's success.
        </p>
        <br></br>
        <h3>Alignment with Company Values:</h3>
        <p className="para">
        Our company values align with the needs and aspirations of our employees. We believe in work-life balance and offer flexible working conditions.
        </p>
        </div> 
      <div className="career-image">
        <img src={img1} alt="Uploading" />
      </div>
        </div>
            <div className="CareerMainContainer">
            <div className='careerbar'></div>
                <div className="headingCareerMain">CAREERS</div>
                <div className="container-careerapply">
                    <div className="careerapply-part1">
                    <div className='careerbar2'></div>
                        <div className="heading-careerapply">LATEST OPENING</div>
                        <div className="para-careerapply">Software Engineer</div>
                        <div className="para-careerapply">System Engineer</div>
                    </div>
                    <div className="careerapply-part2">
                    <div className='careerbar3'></div>
                        <div className="heading-careerapply">DROP YOUR DETAILS</div>
                        <form className='formapply' ref={form} onSubmit={sendEmail}>
                            <input className='input' type="text" id='name' name='name' placeholder='Full Name' required/>
                            <input className='input' type="email" id='email' name='email' placeholder='Email' required/>
                            <input className='input' type="phone" id='phone' name='phone' placeholder='Phone' required/>
                            <input className='input' type="text" id='address' name='address' placeholder='Address' required/>
                            <input className='input' type="text" id='qualification' name='qualification' placeholder='Qualification' required/>
                            <input className='input' type="number" id='experience' name='experience' placeholder='Experience in years' required/>
                            <textarea className='input' name="ExperienceDesc" id="ExperienceDesc" cols="10" rows="2" placeholder='Short description of your experience' required></textarea>
                            <input className='choosefile' type='file' id='file' required/>
                            <button className='btn-apply'>Submit</button>
                            <ToastContainer />
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
