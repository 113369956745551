import './Navbar.css';
import logo from '../../assets/logo/logo.png';
import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import DehazeIcon from '@mui/icons-material/Dehaze';

export default function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  
  const navbarRef = useRef(null);
  const location = useLocation();
  const dropdownRef = useRef(null); // Reference to the dropdown element

  // Toggle dropdown visibility
  const toggleDropdown = (e) => {
    e.preventDefault();
    setDropdownOpen(!isDropdownOpen);
  };

  // Open the submenu for the respective link
  const handleMouseOver = (index) => {
    setOpenSubmenu(index);
  };

  // Close submenu when the mouse leaves the submenu
  const handleMouseOut = () => {
    setOpenSubmenu(null);
  };

  // Close dropdown when clicking outside of it
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
      setOpenSubmenu(null); // Close submenu when dropdown is closed
    }
  };

  // Use effect to add and cleanup event listener
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  
  // Function to close the navbar
  const closeNavbar = () => {
    setIsNavExpanded(false);
    scrollToTop();
  };




  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  };

  // Close the navbar and scroll to top when the route changes
  useEffect(() => {
    closeNavbar();
  }, [location]);

  // Handle scroll event to show/hide navbar
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop) {
        setIsNavbarVisible(false);
      } else {
        setIsNavbarVisible(true);
      }

      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  // Apply the visibility of the navbar using transform style
  useEffect(() => {
    if (navbarRef.current) {
      navbarRef.current.style.transform = isNavbarVisible ? 'translateY(0)' : 'translateY(-100%)';
      navbarRef.current.style.transition = 'transform 0.3s ease';
    }
  }, [isNavbarVisible]);



  

  return (
    <>
      <nav id='navbar' className="navigation" ref={navbarRef}>
        <div className="logo">
          <Link to="/home" onClick={scrollToTop}>
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <button
          className="hamburger"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          <DehazeIcon />
        </button>
        <div
          className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}
        >
          <ul>
            <li className="item">
              <NavLink
                to="/home"
                onClick={closeNavbar}
              >
                Home
              </NavLink>
            </li>
            <li className="item">
              <NavLink
                to="/about"
                className='btn'
                onClick={closeNavbar}
              >
                About Us
              </NavLink>
            </li>
            <li className="item">
              <NavLink
                to="/project"
                className='btn'
                onClick={closeNavbar}
              >
                Projects
              </NavLink>
            </li>

            <div className="dropdown" ref={dropdownRef}>
              
              <li className="item" onClick={toggleDropdown}><NavLink to="/products" onClick={toggleDropdown}>Products</NavLink></li>

              {/* Dropdown content */}
              <div className={`dropdown-content ${isDropdownOpen ? 'show' : ''}`}>
                
                {/* Combustion Control with hoverable submenu */}
                <div
                  className="submenu-wrapper"
                  onMouseOver={() => handleMouseOver(1)}
                  onMouseOut={handleMouseOut}
                >
                  <NavLink to="/products/combustion-control" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>Combustion Control</NavLink>
                  {openSubmenu === 1 && (
                    <div className="submenu-content">
                      <NavLink to="/products/combustion-control/fusion-system" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>Fusion System</NavLink>
                      <NavLink to="/products/combustion-control/oxygen-analyzer" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>OXY3600</NavLink>
                      <NavLink to="/products/combustion-control/emission-monitoring-system" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>EMS3600</NavLink>
                    </div>
                  )}
                </div>
                
                {/* Flow Metering with hoverable submenu */}
                <div
                  className="submenu-wrapper"
                  onMouseOver={() => handleMouseOver(2)}
                  onMouseOut={handleMouseOut}
                >
                  <NavLink to="/products/flow-metering" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>Flow Metering</NavLink>
                  {openSubmenu === 2 && (
                    <div className="submenu-content">
                      <NavLink to="/products/flow-metering/steam-flow-meter" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>FCU5400</NavLink>
                      <NavLink to="/products/flow-metering/thermal-mass-flow-meter" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>OGF5400</NavLink>
                      <NavLink to="/products/flow-metering/electromagnetic-water-flow-meter" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>OWF5400</NavLink>
                      <NavLink to="/products/flow-metering/energy-meter" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>EM900</NavLink>
                    </div>
                  )}
                </div>
                
                {/* Sensors with hoverable submenu */}
                <div
                  className="submenu-wrapper"
                  onMouseOver={() => handleMouseOver(3)}
                  onMouseOut={handleMouseOut}
                >
                  <NavLink to="/products/sensors" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>Sensors</NavLink>
                  {openSubmenu === 3 && (
                    <div className="submenu-content">
                      <NavLink to="/products/sensors/water-quality-analyzer" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>TDS900</NavLink>
                      <NavLink to="/products/sensors/environmental-sensor" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>ES900</NavLink>
                      <NavLink to="/products/sensors/resistor-depending-temperature-sensor" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>RDT900</NavLink>
                      <NavLink to="/products/sensors/temperature-&-humidity-data-terminal" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>OTH900</NavLink>
                      <NavLink to="/products/sensors/ir-temperature-sensor-data-terminal" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>IRT900</NavLink>
                    </div>
                  )}
                </div>

                {/* Generator Set Monitoring with hoverable submenu */}
                <div
                  className="submenu-wrapper"
                  onMouseOver={() => handleMouseOver(4)} // Changed to 4 for unique submenu tracking
                  onMouseOut={handleMouseOut}
                >
                  <NavLink to="/products/generator-set-monitoring" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>Generator Set Monitoring</NavLink>
                  {openSubmenu === 4 && (
                    <div className="submenu-content">
                      <NavLink to="/products/generator-set-monitoring/fuel-level-sensor" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>FLS3600</NavLink>
                      <NavLink to="/products/generator-set-monitoring/genset-health-monitoring-system" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>CELL900G</NavLink>
                    </div>
                  )}
                </div>

                {/* Data Loggers with hoverable submenu */}
                <div
                  className="submenu-wrapper"
                  onMouseOver={() => handleMouseOver(5)} // Changed to 5 for unique submenu tracking
                  onMouseOut={handleMouseOut}
                >
                  <NavLink to="/products/data-loggers" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>Data Loggers</NavLink>
                  {openSubmenu === 5 && (
                    <div className="submenu-content">
                      <NavLink to="/products/data-loggers/gsm-data-logger" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>CELL900D</NavLink>
                      <NavLink to="/products/data-loggers/milk-tank-control-&-monitoring-system" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>CELL900M</NavLink>
                    </div>
                  )}
                </div>

                {/* Condensate Recovery with hoverable submenu */}
                <div
                  className="submenu-wrapper"
                  onMouseOver={() => handleMouseOver(6)} // Changed to 6 for unique submenu tracking
                  onMouseOut={handleMouseOut}
                >
                  <NavLink to="/products/condensate-recovery" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>Condensate Recovery</NavLink>
                  {openSubmenu === 6 && (
                    <div className="submenu-content">
                      <NavLink to="/products/condensate-recovery/condensate-metering-pump" onClick={() => {closeNavbar(); setDropdownOpen(false); setOpenSubmenu(null);}}>OCMP9000</NavLink>
                    </div>
                  )}
                </div>

              </div>
            </div>

            <li className="item">
              <NavLink
                to="/bms"
                className='btn'
                onClick={closeNavbar}
              >
                BMS
              </NavLink>
            </li>
            <li className="item">
              <NavLink
                to="/contact"
                className='btn'
                onClick={closeNavbar}
              >
                Contact Us
              </NavLink>
            </li>
            <li className="item">
              <NavLink
                to="/career"
                className='btn'
                onClick={closeNavbar}
              >
                Careers
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
