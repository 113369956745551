import React from 'react'
import './SubCategories.css'
import pdfdownload from '../../../../../assets/pdf/Temperature & Humidity Data Terminal.pdf'
import img1 from  '../../../../../assets/products/THDT10.png'
import 'react-toastify/dist/ReactToastify.css'

export default function TemperatureHumidityDataTerminal() {
    return (

<div className="container-brochure-main">
        <div className='brochure'>
            <img src={img1} alt="Uploading" />
        </div>
        <a rel='noreferrer' className='pdflink' target={"_blank"} href={pdfdownload} download="Temperature_and_Humidity_Data_Terminal" >Download</a>
        </div>
    )
}