import React from 'react'

import './AboutTeam.css'

import member1 from '../../../assets/about/about team 1.jpg';
import member2 from '../../../assets/about/about team 2.jpg';
import member3 from '../../../assets/about/about team 3.jpg';
import member4 from '../../../assets/about/about team 4.jpg';
import member5 from '../../../assets/about/about team 5.jpg';
import member6 from '../../../assets/about/about team 6.jpg';
import member8 from '../../../assets/about/about team 8.jpg';
import member9 from '../../../assets/about/about team 9.jpg';
import member10 from '../../../assets/about/about team 10.jpg';
import member11 from '../../../assets/about/about team 11.jpg';
import member12 from '../../../assets/about/about team 12.jpg';
import member13 from '../../../assets/about/about team 13.jpg';
import member15 from '../../../assets/about/about team 15.jpg';
import member16 from '../../../assets/about/about team 16.jpg';
import member17 from '../../../assets/about/about team 17.jpg';
import member18 from '../../../assets/about/about team 18.jpg';

const teamMap = [
    {
        teamimg: member1,
        name: "Ahmad Ahtzaz",
        position: "CEO",
    },
    {
        teamimg: member2,
        name: "Muhammad Khurram",
        position: "COO",
    },
    {
        teamimg: member3,
        name: "Adil Maqbool",
        position: "Admin",
    },
    {
        teamimg: member4,
        name: "Jawad Qureshi",
        position: "Business Development Manager",
    },
    {
        teamimg: member5,
        name: "Ali Hussnain",
        position: "Senior Automation Engineer",
    },
    {
        teamimg: member6,
        name: "Hassan Ahmad",
        position: "Software Developer",
    },
    {
        teamimg: member8,
        name: "Asees Ali",
        position: "Graphic Designer",
    },
    {
        teamimg: member9,
        name: "Shabbir Abbas",
        position: "Purchaser",
    },
    {
        teamimg: member10,
        name: "Raheel Ahmad",
        position: "Embedded Engineer",
    },
    {
        teamimg: member11,
        name: "Hadaiq Saeed",
        position: "Web Developer",
    },
    {
        teamimg: member12,
        name: "Sarmad Bashir",
        position: "Accountant",
    },
    {
        teamimg: member13,
        name: "Gulam Ali",
        position: "Associate Engineer",
    },
    {
        teamimg: member15,
        name: "Miqdad Abbas",
        position: "Project Engineer",
    },
    {
        teamimg: member16,
        name: "Asad Ali",
        position: "Associate Engineer",
    },
    {
        teamimg: member17,
        name: "Sundas Ahsan",
        position: "Customer Relationship Manager",
    },
    {
        teamimg: member18,
        name: "Sana Aftab",
        position: "Customer Support Representative",
    },
]

export default function AboutTeam() {
    return (
        <>
            <div className="container-aboutteam">
            <div className='teambar'></div>
                <div className="heading-aboutteam">OUR TEAM</div>
                <div className="aboutallteam">
                    {
                        teamMap.map((team,i) => {
                            return (

                                <div key={i} className="aboutteammember">
                                    <img className='memberimg' src={team.teamimg} alt="Uploading" />
                                    <div className="membername">{team.name}</div>
                                    <div className="memberposition">{team.position}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
