import React from 'react'
import Product from '../Home/Product/ProductProduct/BMS Combustion Control'

export default function BMSCombustionControl() {
  return (
    <>
        <Product/>
    </>
  )
}
