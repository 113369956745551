import React from 'react'
import './Categories.css'

import { useNavigate } from "react-router-dom";

import product11 from '../../../../assets/products/new product 11.png';
import product16 from '../../../../assets/products/new product 16.png';

import { milk, gsm } from './ProductDetail/ProductsArray';

const productMap = [
    {
        productimg: product16,
        heading: "GSM DATA LOGGER",
        para: "The CELL900D is a sophisticated system that offers real-time data transmission, enabling remote monitoring & management of critical parameters.",
        name: "GSM DATA LOGGER",
        arr: gsm,
        path: "/products/data-loggers/gsm-data-logger" // Added path
    },
    {
        productimg: product11,
        heading: "MILK TANK CONTROL & MONITORING SYSTEM",
        para: "CELL900M is a intelligent solution for Controlling & Monitoring the tank. It is very user-friendly, reliable & cost effective as compare with other renown brands.",
        name: "MILK TANK CONTROL & MONITORING SYSTEM",
        arr: milk,
        path: "/products/data-loggers/milk-tank-control-&-monitoring-system" // Added path
    },
]

export default function Product() {
  const navigate = useNavigate();
  return (
      <>
          <div className="container-latestproduct-main">
              <div className='productbar'></div>
              <div className="heading-latestproduct-top">DATA LOGGERS</div>
              <div className="container-latestproduct">
                  {
                      productMap.map((product, i, index) => {
                          return (
                              <div key={i} className="latestproduct">
                                  <div className="img-wrapper" onClick={() => {
                                      navigate(product.path, {
                                          state: {
                                              name: product.name,
                                              arrrr: product.arr,
                                          },
                                      });
                                  }}>
                                      <img className="inner-img" src={product.productimg} alt={product.heading} />
                                  </div>
                                  <div key={index}
                                       className={product.name === "GSM DATA LOGGER" ? 'gsm-padding' : ''} >
                                  <div className="heading-latestproduct">{product.heading}</div></div>
                                  <div className="para-homeproduct">
                                      {product.para}
                                  </div>
                                  <div onClick={() => {
                                      navigate(product.path, {
                                          state: {
                                              name: product.name,
                                              arrrr: product.arr,
                                          },
                                      });
                                  }}>
                                      <div id="container">
                                        <button className="learn-more">
        <span className="circle" aria-hidden="true">
          <span className="icon arrow"></span>
        </span>
        <span className="button-text">Read More</span>
      </button></div>
                                  </div>
                              </div>
                          )
                      })
                  }
              </div>
          </div>
      </>
  )
}
