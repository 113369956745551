import React from 'react'
import './Categories.css'

import { useNavigate } from "react-router-dom";

import product6 from  '../../../../assets/products/new product 6.png';

import { condensate } from './ProductDetail/ProductsArray';

const productMap = [
  
    {
        productimg: product6,
        heading: "CONDENSATE METERING PUMP",
        para: "OCMP9000 are accurate, reliable & very cost effective as compare with other renown brands. Latest metering pumps are available from 30 Liters to larger volume up to 120 Liters.",
        name: "CONDENSATE METERING PUMP",
        arr: condensate,
        path: "/products/condensate-recovery/condensate-metering-pump" // Added path
    },
    
]

export default function Product() {
  const navigate = useNavigate();
  return (
      <>
          <div className="container-latestproduct-main">
              <div className='productbar'></div>
              <div className="heading-latestproduct-top">CONDENSATE RECOVERY</div>
              <div className="container-latestproduct">
                  {
                      productMap.map((product, i) => {
                          return (
                              <div key={i} className="latestproduct">
                                  <div className="img-wrapper" onClick={() => {
                                      navigate(product.path, {
                                          state: {
                                              name: product.name,
                                              arrrr: product.arr,
                                          },
                                      });
                                  }}>
                                      <img className="inner-img" src={product.productimg} alt={product.heading} />
                                  </div>
                                  <div className="heading-latestproduct">{product.heading}</div>
                                  <div className="para-homeproduct">
                                      {product.para}
                                  </div>
                                  <div onClick={() => {
                                      navigate(product.path, {
                                          state: {
                                              name: product.name,
                                              arrrr: product.arr,
                                          },
                                      });
                                  }}>
                                      <div id="container">
                                        <button className="learn-more">
        <span className="circle" aria-hidden="true">
          <span className="icon arrow"></span>
        </span>
        <span className="button-text">Read More</span>
      </button></div>
                                  </div>
                              </div>
                          )
                      })
                  }
              </div>
          </div>
      </>
  )
}

