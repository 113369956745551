import React from 'react'
import Product from '../Home/Product/ProductProduct/Sensors'

export default function Sensors() {
  return (
    <>
        <Product/>
    </>
  )
}
