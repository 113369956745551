import React from 'react'
import './Categories.css'

import { useNavigate } from "react-router-dom";

import product7 from  '../../../../assets/products/new product 7.png';
import product9 from  '../../../../assets/products/new product 9.png';
import product10 from '../../../../assets/products/new product 10.png';
import product13 from '../../../../assets/products/new product 13.png';
import product14 from '../../../../assets/products/new product 14.png';

import { water, tempsensor, temphumidity,  resistor, environmental } from './ProductDetail/ProductsArray';

const productMap = [
    {
        productimg: product7,
        heading: "WATER QUALITY ANALYZER",
        para: "TDS900 is an advanced solution for analyzing water quality. This is accurate, reliable & very cost effective as compare with other renown brands.",
        name: "WATER QUALITY ANALYZER",
        arr: water,
        path: "/products/sensors/water-quality-analyzer" // Added path
    },
    {
        productimg: product14,
        heading: "ENVIRONMENTAL SENSOR",
        para: "The ES900 is a system ensures accurate & reliable emission data, enabling industries to maintain compliance with regulatory standards efficiently.",
        name: "ENVIRONMENTAL SENSOR",
        arr: environmental,
        path: "/products/sensors/environmental-sensor" // Added path
    },
    {
        productimg: product13,
        heading: "RESISTOR DEPENDING TEMPERATURE SENSOR",
        para: "RDT900 offers precise temperature & monitoring with advanced sensor technology as compare with other well-known brands.",
        name: "RESISTOR DEPENDING TEMPERATURE SENSOR",
        arr: resistor,
        path: "/products/sensors/resistor-depending-temperature-sensor" // Added path
    },
    {
        productimg: product10,
        heading: "TEMPERATURE & HUMIDITY DATA TERMINAL",
        para: "OTH900 offers precise temperature & humidity monitoring with advanced sensor technology as compare with other well-known brands.",
        name: "TEMPERATURE & HUMIDITY DATA TERMINAL",
        arr: temphumidity,
        path: "/products/sensors/temperature-&-humidity-data-terminal" // Added path
    },
    {
        productimg: product9,
        heading: "IR TEMPERATURE SENSOR DATA TERMINAL",
        para: "The IRT900 ensures precise monitoring of temperature levels with advanced sensor capabilities, making it an ideal choice for reliable temperature management in various applications.",
        name: "IR TEMPERATURE SENSOR DATA TERMINAL",
        arr: tempsensor,
        path: "/products/sensors/ir-temperature-sensor-data-terminal" // Added path
    },
]

export default function Product() {
    const navigate = useNavigate();
    return (
        <>
            <div className="container-latestproduct-main">
                <div className='productbar'></div>
                <div className="heading-latestproduct-top">SENSORS</div>
                <div className="container-latestproduct">
                    {
                        productMap.map((product, i) => {
                            return (
                                <div key={i} className="latestproduct">
                                    <div className="img-wrapper" onClick={() => {
                                        navigate(product.path, {
                                            state: {
                                                name: product.name,
                                                arrrr: product.arr,
                                            },
                                        });
                                    }}>
                                        <img className="inner-img" src={product.productimg} alt={product.heading} />
                                    </div>
                                    <div className="heading-latestproduct">{product.heading}</div>
                                    <div className="para-homeproduct">
                                        {product.para}
                                    </div>
                                    <div onClick={() => {
                                        navigate(product.path, {
                                            state: {
                                                name: product.name,
                                                arrrr: product.arr,
                                            },
                                        });
                                    }}>
                                        <div id="container">
                                        <button className="learn-more">
        <span className="circle" aria-hidden="true">
          <span className="icon arrow"></span>
        </span>
        <span className="button-text">Read More</span>
      </button></div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
  }