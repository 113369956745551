import React from 'react';
import './Categories.css';

import { useNavigate } from "react-router-dom";

import product1 from  '../../../../assets/products/new product 1.png';
import product2 from  '../../../../assets/products/new product 2.png';
import product12 from '../../../../assets/products/new product 12.png';

import { fusion, oxygen, emission } from './ProductDetail/ProductsArray';

const productMap = [
    {
        productimg: product1,
        heading: "BMS COMBUSTION CONTROL",  
        para: "FUSION is a pre-engineered Automation System for gas/oil fired industrial steam boilers water tube/smoke tube, gas/oil fired chillers, furnaces & duct burners.",
        name: "BMS COMBUSTION CONTROL",
        arr: fusion,
        path: "/products/combustion-control/fusion-system" // Added path
    },
    {
        productimg: product2,
        heading: "OXYGEN ANALYZER",
        para: "The OXY3600 oxygen probe with zirconia sensor & process display unit are the most advanced process system as compare other similar systems available.",
        name: "OXYGEN ANALYZER",
        arr: oxygen,
        path: "/products/combustion-control/oxygen-analyzer" // Added path
    },
    {
        productimg: product12,
        heading: "EMISSION MONITORING SYSTEM",
        para: "The EMS3600 is a system ensures accurate & reliable emission data, enabling industries to maintain compliance with regulatory standards efficiently.",
        name: "EMISSION MONITORING SYSTEM",
        arr: emission,
        path: "/products/combustion-control/emission-monitoring-system" // Added path
    },
];

export default function Product() {
    const navigate = useNavigate();
    return (
        <>
            <div className="container-latestproduct-main">
                <div className='productbar'></div>
                <div className="heading-latestproduct-top">COMBUSTION CONTROL</div>
                <div className="container-latestproduct">
                    {
                        productMap.map((product, i) => {
                            return (
                                <div key={i} className="latestproduct">
                                    <div className="img-wrapper" onClick={() => {
                                        navigate(product.path, {
                                            state: {
                                                name: product.name,
                                                arrrr: product.arr,
                                            },
                                        });
                                    }}>
                                        <img className="inner-img" src={product.productimg} alt={product.heading} />
                                    </div>
                                    <div className="heading-latestproduct">{product.heading}</div>
                                    <div className="para-homeproduct">
                                        {product.para}
                                    </div>
                                    <div onClick={() => {
                                        navigate(product.path, {
                                            state: {
                                                name: product.name,
                                                arrrr: product.arr,
                                            },
                                        });
                                    }}><div id="container">
                                        <button className="learn-more">
        <span className="circle" aria-hidden="true">
          <span className="icon arrow"></span>
        </span>
        <span className="button-text">Read More</span>
      </button></div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
