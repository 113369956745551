import React from 'react'
import { useNavigate } from 'react-router-dom';
import './Catalog.css'; 
import img1 from '../../../assets/background/CBanner.png';
import productcatalog from '../../../assets/pdf/Hunch Automation Products Catalog.pdf'

const Catalog = () => {
  const navigate = useNavigate();

  const catalog = () => {
    navigate('/');
  };

  return (
    <div className="catalog-container">
      <div className="catalog-image">
        <img src={img1} alt="Uploading" />
        <a rel='noreferrer' className='catalog-btn' target={"_blank"} href={productcatalog} download="Hunch_Automation_Products_Catalog" >Download Products Catalog</a>
      </div>
    </div>
  );
};

export default Catalog;