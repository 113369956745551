import React from 'react'
import './Categories.css'

import { useNavigate } from "react-router-dom";

import product3 from  '../../../../assets/products/new product 3.png';
import product4 from  '../../../../assets/products/new product 4.png';
import product8 from  '../../../../assets/products/new product 8.png';
import product17 from  '../../../../assets/products/new product 17.png';
import { steam, airgas, electro, energy } from './ProductDetail/ProductsArray';

const productMap = [
    {
        productimg: product3,
        heading: "STEAM FLOW METER",
        para: "FCU5400 is a Differential Pressure (DP) based flow measurements with temperature & pressure compensation. ",
        name: "STEAM FLOW METER",
        arr: steam,
        path: "/products/flow-metering/steam-flow-meter" // Added path
    },
    {
        productimg: product4,
        heading: "AIR/GAS FLOW METER",
        para: "OGF5400 latest version is an intelligent solution for accurate consumption measurement for compressed air & gases.",
        name: "AIR/GAS FLOW METER",
        arr: airgas,
        path: "/products/flow-metering/thermal-mass-flow-meter" // Added path
    },
    {
        productimg: product17,
        heading: "ELECTROMAGNETIC WATER FLOW METER",
        para: "OWF5400 is an affordable insertion type Electromagnetic Water Flow Meter which introduces a groundbreaking approach to accurately measure the flow of water.",
        name: "ELECTROMAGNETIC WATER FLOW METER",
        arr: electro,
        path: "/products/flow-metering/electromagnetic-water-flow-meter" // Added path
    },
    {
        productimg: product8,
        heading: "ENERGY METER",
        para: "EM900 is an intelligent solution for accurate consumption & measurement of electric energy consumed by a Industry, or an electrically powered device.",
        name: "ENERGY METER",
        arr: energy,
        path: "/products/flow-metering/energy-meter" // Added path
    },
    
]

export default function Product() {
  const navigate = useNavigate();
  return (
      <>
          <div className="container-latestproduct-main">
              <div className='productbar'></div>
              <div className="heading-latestproduct-top">FLOW METERING</div>
              <div className="container-latestproduct">
                  {
                      productMap.map((product, i, index) => {
                          return (
                              <div key={i} className="latestproduct">
                                  <div className="img-wrapper" onClick={() => {
                                      navigate(product.path, {
                                          state: {
                                              name: product.name,
                                              arrrr: product.arr,
                                          },
                                      });
                                  }}>
                                      <img className="inner-img" src={product.productimg} alt={product.heading} />
                                  </div>
                                  <div key={index}
                                       className={product.name === "ENERGY METER" ? 'em-padding' : ''} >
                                  <div className="heading-latestproduct">{product.heading}</div></div>
                                  <div className="para-homeproduct">
                                      {product.para}
                                  </div>
                                  <div onClick={() => {
                                      navigate(product.path, {
                                          state: {
                                              name: product.name,
                                              arrrr: product.arr,
                                          },
                                      });
                                  }}>
                                      <div id="container">
                                        <button className="learn-more">
        <span className="circle" aria-hidden="true">
          <span className="icon arrow"></span>
        </span>
        <span className="button-text">Read More</span>
      </button></div>
                                  </div>
                              </div>
                          )
                      })
                  }
              </div>
          </div>
      </>
  )
}