import React from 'react'
import './SubCategories.css'
import pdfdownload from '../../../../../assets/pdf/Resistor Depending Temperature Sensor.pdf'
import img1 from  '../../../../../assets/products/RDT13.png'
import 'react-toastify/dist/ReactToastify.css'

export default function ResistorDependingTemperatureSensor() {
    return (

<div className="container-brochure-main">
        <div className='brochure'>
            <img src={img1} alt="Uploading" />
        </div>
        <a rel='noreferrer' className='pdflink' target={"_blank"} href={pdfdownload} download="Resistor_Depending_Temperature_Sensor" >Download</a>
        </div>
    )
}