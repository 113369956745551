import React from 'react'
import Product from '../Home/Product/ProductProduct/Generator Set Monitoring'

export default function GeneratorSetMonitoring() {
  return (
    <>
        <Product/>
    </>
  )
}
